import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'redux-react-hook';
import { useTranslation } from 'react-i18next';

import { getSurvey, saveSurveyQuestions } from '~/api/research';

import { showMessage } from '~/store/ducks/messageBar';
import {
  openDefaultModal,
  closeDefaultModal
} from '~/store/ducks/default-modal';

import Fab from '~/components/fab';
import Card from '~/components/card';
import Icon from '~/components/icon';
import Button from '~/components/button';
import ConfirmModal from '~/components/confirm-modal';
import CustomHeader from '~/components/custom-header';
import SuspendedMenu from '~/components/suspended-menu';
import CustomLoading from '~/components/custom-loading';
import { research_corver as ResearchIcon } from '~/components/svg-icons/svg-icons';

import { StyledFeedbackForm } from './styles';
import ResearchFormQuestion from './components/research-form-question';

import {
  getResponse,
  saveResponse,
  removeResponseById
} from '~/idb/survey-responses';

import moment from 'moment';

function ResearchForm({
  organization,
  message: { showMessage },
  closeDefaultModal,
  location,
  history
}) {
  const { researchId = null } = location.state || {};

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const userLanguage = i18n.language;

  // Research states
  const [researchData, setResearchData] = useState({});
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [savedResponse, setSavedResponse] = useState({
    isFetching: true,
    data: []
  });
  const [sendLoading, setSendLoading] = useState(false);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleBack = () => {
    history.push('/');
  };

  const paginateArray = (array, page_size = 10, page_number = 1) => {
    const startIndex = (page_number - 1) * page_size;
    const endIndex = startIndex + page_size;
    return array.slice(startIndex, endIndex);
  };

  const replacePageArray = (
    array,
    page_size = 10,
    page_number = 1,
    newItems = []
  ) => {
    const startIndex = (page_number - 1) * page_size;
    const newArray = [...array];
    newArray.splice(startIndex, page_size, ...newItems);
    return newArray;
  };

  const getSaveData = () => {
    const KEYS = {
      open_answer: 'text',
      point_scale: 'scale'
    };

    const questionList = questions.map(question => {
      const currentKey = KEYS[question.category];
      return {
        id: question.id,
        [currentKey]: question.value === '' ? null : question.value
      };
    });

    return questionList;
  };

  const confirmSend = () => {
    dispatch(
      openDefaultModal(
        <ConfirmModal
          title={t('Enviar pesquisa?')}
          description={t('Tem certeza que deseja enviar esta pesquisa?')}
          confirmButtonText={t('Enviar')}
          onConfirm={handleSend}
        />
      )
    );
  };

  const handleSend = async () => {
    setSendLoading(true);
    const data = getSaveData();
    closeDefaultModal();
    const pageSize = 10;
    const totalPages = Math.ceil(data.length / pageSize);
    let hasError = false;

    for (let page = 1; page <= totalPages; page++) {
      const percent = Math.ceil((page * 100) / totalPages);
      const currentQuestionsBatch = paginateArray(data, 10, page);

      try {
        await saveSurveyQuestionsBatch({
          questions: currentQuestionsBatch,
          partial: true
        });
        showMessage(`${percent}% ` + t('Enviando pesquisa...'), 'success');
      } catch (err) {
        const data = 'data' in err.response ? err.response.data : undefined;
        if (data && data.code === 'field_error') {
          showMessage(
            t(
              'Ocorreu um erro ao enviar a pesquisa, por favor verifique as questões e tente novamente.'
            ),
            'danger'
          );

          if ('errors' in data && 'questions' in data.errors) {
            handleSurveyError(data.errors.questions, page);
          }
        } else {
          history.push('/unexpected-error');
        }
        hasError = true;
        break;
      }
    }

    if (!hasError) {
      try {
        await saveSurveyQuestionsBatch({
          questions: [],
          partial: false
        });

        showMessage(t('Sua resposta foi enviada com sucesso.'), 'success');
        removeResponseById(researchId);
        history.push('/');
      } catch (error) {
        if (error.response.status === 400) {
          showMessage(error.response.data.errors.questions, 'danger');
          setSendLoading(false);
          return;
        }

        history.push('/unexpected-error');
      }
    }

    setSendLoading(false);
  };

  const handleSurveyError = (errors, page) => {
    const currentQuestionsBatch = paginateArray(questions, 10, page);

    errors.forEach(error => {
      if ('fields' in error) {
        const errorField = error.fields[0];

        if (errorField && 'errors' in errorField) {
          const errorMessage = errorField['errors'][0].error;
          currentQuestionsBatch[parseInt(error.index)] = {
            ...currentQuestionsBatch[parseInt(error.index)],
            error: {
              hasError: true,
              message: errorMessage
            }
          };

          const newQuestions = replacePageArray(
            [...questions],
            10,
            page,
            currentQuestionsBatch
          );
          setQuestions(newQuestions);
        }
      }
    });
  };

  const saveSurveyQuestionsBatch = async questions => {
    return await saveSurveyQuestions(researchId, questions);
  };

  const getSavedQuestionValueById = id => {
    const question = savedResponse.data.find(question => question.id === id);
    return question ? question.value : '';
  };

  useEffect(() => {
    const loadSavedResponse = async () => {
      const response = await getResponse(researchId);
      if (response) {
        setSavedResponse(() => ({
          isFetching: false,
          data: response.data
        }));
      } else {
        setSavedResponse(prevData => ({
          data: [],
          isFetching: false
        }));
      }
    };

    if (researchId) {
      loadSavedResponse();
    } else {
      setSavedResponse(prevData => ({
        data: [],
        isFetching: false
      }));
    }
  }, []);

  useEffect(() => {
    if (!savedResponse.isFetching) {
      getSurvey(researchId)
        .then(response => {
          const { questions: questionList } = response.data;
          setQuestions(() =>
            questionList.map(item => {
              const currentValue = getSavedQuestionValueById(item.id);
              return {
                ...item,
                value: currentValue,
                error: {
                  hasError: false,
                  message: ''
                }
              };
            })
          );

          delete response.data['questions'];
          setResearchData(response.data);
        })
        .catch(error => {
          if (error.response.status === 404 || error.response.status === 403) {
            showMessage(
              t('Esta pesquisa não está disponível.'),
              'danger',
              3000
            );
            history.push('/');
            return;
          }

          history.push('/unexpected-error');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [savedResponse.isFetching]);

  const onChangeQuestionHandler = async (index, value) => {
    setQuestions(prevQuestions => {
      const currentQuestions = [...prevQuestions];
      currentQuestions[index].value = value;
      currentQuestions[index].error = {
        hasError: false,
        message: ''
      };
      return currentQuestions;
    });

    await saveResponse({
      id: researchId,
      data: questions.map(question => {
        return { id: question.id, value: question.value };
      })
    });
  };

  const handleInstructionModal = () => {
    dispatch(
      openDefaultModal(
        <div className="instruction-modal" style={{ marginBottom: '16px' }}>
          <h3 style={{ fontSize: '24px', marginBottom: '28px' }}>
            {t('Instruções da pesquisa')}
          </h3>
          <p style={{ whiteSpace: 'pre-line' }}>{researchData.instructions}</p>
        </div>
      )
    );
  };

  return (
    <StyledFeedbackForm>
      <CustomHeader expanded>
        <div className="header-actions">
          <div className="left-side">
            <Button color="primary" className="link" onClick={handleBack}>
              <Icon name="back" />
              {t('Voltar')}
            </Button>
          </div>
          <div className="right-side">
            <Button
              color="primary"
              disabled={loading || sendLoading}
              onClick={confirmSend}
            >
              {!sendLoading && (
                <>
                  <Icon name={'publishing'} marginRight />
                  <span>{t('Enviar')}</span>
                </>
              )}
              {sendLoading && (
                <CustomLoading
                  type="spin"
                  height={16}
                  width={16}
                  fluid
                  color="#FFFFFF"
                />
              )}
            </Button>

            <SuspendedMenu>
              <Button
                color="primary"
                className="outline"
                disabled={loading || sendLoading}
                onClick={confirmSend}
              >
                {!sendLoading && (
                  <>
                    <Icon name={'publishing'} marginRight />
                    <span>{t('Enviar')}</span>
                  </>
                )}
                {sendLoading && (
                  <CustomLoading
                    type="spin"
                    height={16}
                    width={16}
                    fluid
                    color="#FFFFFF"
                  />
                )}
              </Button>
            </SuspendedMenu>
          </div>
        </div>
      </CustomHeader>

      <div className="page-content">
        {!loading && (
          <>
            <Card className="research-form">
              {researchData.cover && (
                <div className="research-cover">
                  <img src={researchData.cover} alt="research cover" />
                </div>
              )}
              {!researchData.cover && (
                <div className="cover-undraw-img">
                  <ResearchIcon width={350} height={147.77} />
                </div>
              )}
              <div className="custom-form-header">
                <div className="text-info">
                  <h2 className="title">{researchData.title}</h2>
                  <p className="description">{researchData.description}</p>
                  <div className="more-info">
                    {researchData && researchData.instructions && (
                      <Button
                        color={'primary'}
                        onClick={handleInstructionModal}
                      >
                        {t('Instruções')}
                      </Button>
                    )}
                    {researchData && researchData.finish_at && (
                      <div className="finish-at">
                        <span>{t('Finaliza em')}</span>
                        <span>
                          {moment
                            .parseZone(researchData.start_at)
                            .locale(userLanguage)
                            .fromNow(true)}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Card>
            <Card className="research-form">
              {questions.map((question, index) => {
                return (
                  <ResearchFormQuestion
                    key={question.pk}
                    index={index}
                    currentQuestion={question}
                    questions={questions}
                    setQuestion={onChangeQuestionHandler}
                    disabled={sendLoading}
                  />
                );
              })}
            </Card>

            <Fab>
              <Button
                color="dark"
                className="rounded go-to-top"
                onClick={scrollToTop}
              >
                <Icon name="arrow-up" />
              </Button>
            </Fab>
          </>
        )}

        {loading && <CustomLoading type="spin" height={56} width={56} fluid />}
      </div>
    </StyledFeedbackForm>
  );
}

const mapDispatchToProps = dispatch => ({
  closeDefaultModal: () => {
    dispatch(closeDefaultModal());
  },
  message: bindActionCreators(
    {
      showMessage
    },
    dispatch
  )
});

const mapStateToProps = ({ account, organization }) => ({
  account,
  organization
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ResearchForm));
