import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useMappedState } from 'redux-react-hook';
import EmptyScreen from '~/components/empty-screen';
import Feed from '~/components/feed';
import LoadingMessage from '~/components/loading-message';
import Tutorial from '~/components/tutorial';
import useAccount from '~/hooks/use-account';
import { taskFinished } from '~/store/ducks/actions';
import { load, loadMore } from '~/store/ducks/feed';
import { skipTutorial } from '~/store/ducks/tutorial';
import SearchArea from '~/components/search-area';
import useVersion from '~/hooks/use-version';
import useSite from '~/hooks/use-site';
import { closeMessageModal } from '~/store/ducks/message-modal';
import EmptyList from '~/components/empty-list';
import Card from '~/components/card';
import Button from '~/components/button';
import EditorialLineCard from '~/components/editorial-line-card';
import EditorialLineIndicator from '~/components/editorial-line-indicator';
import { getEditorialLines } from '~/api/organization';

import './styles.scss';

const EmptyHome = () => (
  <EmptyScreen name="home">
    <span>As experiências acumuladas demonstram que a percepção </span>
    <span>das dificuldades prepara-nos para enfrentar situações </span>
    <span>atípicas decorrentes das diversas correntes de pensamento.</span>
  </EmptyScreen>
);

let FeedScreen = ({ location }) => {
  const dispatch = useDispatch();
  const { termo: search, task, post } = queryString.parse(
    location.hash !== '' ? location.search + location.hash : location.search
  );
  const currentEditorialId = queryString.parse(location.search).editorial || '';

  let keepModalOpen = false; // Mantem o modal aberto após o redirecionamento...

  if (location.state) {
    keepModalOpen = location.state.keepModalOpen;
  }

  const mapStateToProps = useCallback(
    ({ feed: { data, loading, nextUrl, total, loadingMore } }) => ({
      feed: data,
      loading,
      loadingMore,
      nextUrl,
      total
    }),
    []
  );

  const { feed, loading, loadingMore, nextUrl, total } = useMappedState(
    mapStateToProps
  );

  useEffect(() => {
    // dispatch(reset());
    dispatch(load(search, task, post, currentEditorialId));
    if (!keepModalOpen) {
      dispatch(closeMessageModal());
    }
  }, [search, location.key, currentEditorialId]);

  // TODO
  const [editorialLines, setEditorialLines] = useState([]);

  useEffect(() => {
    getEditorialLines().then(response => {
      setEditorialLines(response.data);
    });
  }, []);

  const onLoadMore = useCallback(() => {
    if (loadingMore || !nextUrl) {
      return;
    }

    dispatch(loadMore(nextUrl));
  }, [loadingMore, nextUrl]);

  const onFinishItem = useCallback(({ type, object: { id } }) => {
    dispatch(taskFinished(type, id));
  }, []);

  const version = useVersion();

  if (loading && feed.length === 0) {
    return <LoadingMessage />;
  }

  if (
    !loading &&
    feed.length === 0 &&
    (location.search.indexOf('task=') !== -1 ||
      location.search.indexOf('post=') !== -1)
  ) {
    return (
      <div>
        <Card>
          <Button
            to="/"
            style={{
              color: '#000',
              width: '100%',
              textAlign: 'center'
            }}
          >
            <span className="default-link">Exibir todos os itens</span>
          </Button>
        </Card>
        <EmptyList message="Este conteúdo não está disponível." />
      </div>
    );
  }

  if (!loading && feed.length === 0 && !search && !currentEditorialId) {
    return <EmptyHome />;
  }

  return (
    <>
      {version === 'mobile' && (
        <div className="mobile-search-wrapper">
          <SearchArea />
        </div>
      )}
      {loading && <LoadingMessage />}

      {!loading && (
        <>
          <div className="main-editorial">
            {editorialLines.length > 1 && (
              <>
                <EditorialLineCard orgEditorialLines={editorialLines} />
                <EditorialLineIndicator
                  orgEditorialLines={editorialLines}
                  currentEditorialId={currentEditorialId}
                />
              </>
            )}
          </div>
        </>
      )}

      {!loading && (
        <Feed
          total={total}
          search={search}
          results={feed}
          currentEditorialId={currentEditorialId}
          onScrollFinish={onLoadMore}
          onFinishItem={onFinishItem}
          location={location}
        />
      )}

      {loadingMore && <LoadingMessage />}
    </>
  );
};

FeedScreen = withRouter(FeedScreen);

const TutorialScreen = ({ account: { profile, date_joined } }) => {
  const dispatch = useDispatch();
  const onFinish = useCallback(() => dispatch(skipTutorial()), []);

  return (
    <Tutorial date={date_joined} name={profile.name} onFinish={onFinish} />
  );
};

let HomeScreen = ({ location }) => {
  const mapStateToProps = useCallback(({ tutorial }) => ({
    tutorial
  }));
  const { name: siteName } = useSite();
  const account = useAccount();
  const { tutorial } = useMappedState(mapStateToProps);
  const { profile } = account.data;

  if (
    typeof profile.gender !== 'string' &&
    !tutorial.skip &&
    siteName !== 'USEFLOW'
  ) {
    return <TutorialScreen account={account.data} />;
  } else {
    return <FeedScreen key="feed" account={account.data} />;
  }
};

HomeScreen = withRouter(HomeScreen);

const HomeScreenGuaranteeAccount = () => {
  const { loaded } = useAccount();

  if (!loaded) {
    return <LoadingMessage />;
  }

  return <HomeScreen />;
};

export default HomeScreenGuaranteeAccount;
