import _ from 'lodash';
import React, { PureComponent, useEffect } from 'react';
import memoize from 'memoize-one';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Icon from '~/components/icon';
import PageHeader from '~/components/page-header';
import './style.scss';
import {
  unregisterAccount,
  updateProfile as dispatchUpdateProfile
} from '~/store/ducks/account';
import { showMessage } from '~/store/ducks/messageBar';
import { fetchHistory } from '~/api/account';
import Loading from '~/components/loading';
import api from '~/api/api';
import useVersion from '~/hooks/use-version';
import Star from '~/assets/images/star.svg';

// **********
// Para aplicar a tradução em componentes de classe, use o
// withTranslation do i18next
// **********

// type acesso, provavelmente acesso a plataforma
// type wololo, que eu tenho as tasks dentro
//
//

const wololoActionTypes = {
  share: 'Compartilhou',
  comment: 'Comentou',
  like: 'Curtiu',
  tweet: 'Postou',
  retweet: 'Repostou',
  watch: 'Assistiu',
  message: 'Enviou mensagem',
  image: 'Enviou imagem',
  movie: 'Comparitlhou vídeo',
  read: 'Leu',
  open_url: 'Abriu url',
  drive: 'Abriu url do drive',
  poll: 'Respondeu',
  checkin: 'Fez checkin'
};

const wololoActionNames = {
  profile: 'Ação de perfil',
  publishing: 'Publicou',
  twitter: 'X (antigo Twitter)',
  facebook: 'Facebook',
  instagram: 'Instagram',
  linkedin: 'Linkedin',
  whatsapp: 'Whatsapp'
};

class HistoryItem extends PureComponent {
  getDateInfo() {
    const historyMoment = moment(this.props.history.object.created_at);
    return (
      <>
        Dia {historyMoment.date()} <br />
        {historyMoment.format('HH:mm')}
      </>
    );
  }

  getDateInfoMobile() {
    const historyMoment = moment(this.props.history.object.created_at);

    return `Dia ${historyMoment.date()} ${historyMoment.format('HH:mm')}`;
  }

  getActionName() {
    const { history } = this.props;

    switch (history.type) {
      case 'wololo': {
        return (
          wololoActionTypes[history.object.task.type] ||
          wololoActionNames[
            `${history.object.task.channel}_${history.object.task.type}`
          ] ||
          wololoActionNames[history.object.task.channel] ||
          `${history.object.task.channel}_${history.object.task.type}`
        );
      }

      case 'access': {
        return 'Acessou';
      }

      case 'badge': {
        return 'Medalha';
      }

      case 'research': {
        return 'Respondeu';
      }

      default: {
        return null;
      }
    }
  }

  getText() {
    const { history } = this.props;

    switch (history.type) {
      case 'wololo': {
        return history.object.task.title;
      }

      case 'access': {
        return 'Acessou a plataforma no dia.';
      }

      case 'badge': {
        return `Conquistou a badge ${history.object.type.name}.`;
      }

      case 'research': {
        return `Pesquisa ${history.object.survey.title}.`;
      }

      default: {
        return null;
      }
    }
  }

  getDescription() {
    const { history } = this.props;

    switch (history.type) {
      case 'wololo': {
        return history.object.task.description;
      }
      default: {
        return null;
      }
    }
  }

  getLink() {
    const { history } = this.props;
    switch (history.type) {
      case 'wololo': {
        return history.object.task.wololo_target_url;
      }
      default: {
        return null;
      }
    }
  }

  getIcon() {
    const { history } = this.props;

    switch (history.type) {
      case 'wololo': {
        const icon =
          history.object.task.channel === 'profile'
            ? 'member-ok'
            : history.object.task.channel;

        return (
          <div className={`social-icon ${icon === 'twitter' ? 'x' : icon}`}>
            <Icon name={icon === 'twitter' ? 'x' : icon} />
          </div>
        );
      }
      case 'access': {
        return (
          <div className="social-icon check">
            <Icon name="check" />
          </div>
        );
      }
      case 'badge': {
        return (
          <img
            src={history.object.type.image}
            className="rounded history-image-icon"
            alt=""
          />
        );
      }
      case 'research': {
        return (
          <div className="social-icon publishing">
            <Icon name="publishing" />
          </div>
        );
      }
      default: {
        return null;
      }
    }
  }

  handled() {
    switch (this.props.history.type) {
      case 'wololo':
      case 'badge':
      case 'research':
      case 'access': {
        return true;
      }
      default: {
        return false;
      }
    }
  }

  getOrganizationImage() {
    const imageUrl = _.get(this.props.history, 'organization.avatar_128x0');

    return (
      imageUrl && (
        <img
          src={imageUrl}
          className="organization-image"
          alt={this.props.history.organization.name}
        />
      )
    );
  }

  render() {
    const { history, avatar, version } = this.props;

    // if (!this.handled()) {
    //   return <p>unhandled history, {JSON.stringify(history)}</p>;
    // }

    if (version === 'desktop') {
      return (
        <div className="historic-item">
          <div className="flex flex-middle w150 mr20 light lh1_2">
            <img
              className="history-image-icon rounded mr10"
              src={avatar}
              alt="Avatar"
            />
            <div>{this.getDateInfo()}</div>
          </div>

          <div className="flex flex-middle w150 mr20">
            <div className="no-flex">{this.getIcon()}</div>
            <div>
              {this.getActionName()}
              {history.object.points ? (
                <>
                  <br />
                  {history.object.status !== 'validating' ? (
                    <span className="points">
                      {history.object.points} pontos
                    </span>
                  ) : (
                    <span className="points">Em validação</span>
                  )}
                </>
              ) : null}
            </div>
          </div>

          <div style={{ flex: 1 }}>
            {this.getText()}
            {this.getDescription() !== null ? (
              <div>
                <br />
                {this.getDescription()}
              </div>
            ) : null}
            {this.getLink() !== null ? (
              <div>
                <br />
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={this.getLink()}
                >
                  Link
                </a>
              </div>
            ) : null}
          </div>
        </div>
      );
    } else {
      return (
        <div className="historic-item-mobile">
          <span className="date-info">
            {this.getOrganizationImage()}

            {this.getDateInfoMobile()}
          </span>

          <p>
            {this.getText()}
            {this.getDescription() !== null ? (
              <div>
                <br />
                {this.getDescription()}
              </div>
            ) : null}
            {this.getLink() !== null ? (
              <div>
                <br />
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={this.getLink()}
                >
                  Link
                </a>
              </div>
            ) : null}
          </p>

          <div className="historic-footer">
            <div>
              {history.object.points ? (
                <>
                  <img src={Star} alt="Points" className="star" />
                  {history.object.status !== 'validating' ? (
                    <span className="points">{history.object.points}pts</span>
                  ) : (
                    <span className="points">Em validação</span>
                  )}
                </>
              ) : null}
            </div>

            <div>
              {this.getActionName()}
              {this.getIcon()}
            </div>
          </div>
        </div>
      );
    }
  }
}

const ReachedBottom = ({ callback }) => {
  useEffect(() => {
    const onScroll = () => {
      const { offsetHeight, scrollHeight } = document.body;
      const scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;

      if (offsetHeight + offsetHeight * 0.5 + scrollTop >= scrollHeight) {
        callback();
      }
    };

    document.addEventListener('scroll', onScroll);

    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, [callback]);

  return null;
};

const HistoryScreen = ({
  historiesGroups,
  hasNext,
  onNext,
  loading,
  avatar
}) => {
  const version = useVersion();

  if (!version) {
    return null;
  }

  return (
    <>
      <PageHeader>
        <div className="text-info">
          <h1>Histórico de Atividades</h1>
          <p>
            Esse é o histórico de todas as atividades que geraram pontuação ou
            conquistas para você.
          </p>
        </div>
      </PageHeader>
      <div className="account-screen">
        {historiesGroups.map(histories => (
          <div key={histories[0].object.created_at}>
            <div className="historic-date-separator">
              {moment(histories[0].object.created_at).format('MMMM - YYYY')}
            </div>

            {histories.map(history => (
              <HistoryItem
                key={`${history.type}_${history.object.id}`}
                history={history}
                avatar={avatar}
                version={version}
              />
            ))}
          </div>
        ))}

        <Loading inline visible={loading} />

        {hasNext && <ReachedBottom callback={onNext} />}
      </div>
    </>
  );
};

class HistoryScreenEnhanced extends PureComponent {
  state = {
    nextUrl: null,
    loading: false,
    histories: []
  };

  componentDidMount() {
    this.load();
  }

  handleResult = ({ data: { next, results } }) => {
    this.setState({
      nextUrl: next,
      histories: [...this.state.histories, ...results]
    });
  };

  removeLoading = () => {
    this.setState({ loading: false });
  };

  next = () => {
    if (this.state.loading) {
      return;
    }

    this.setState({ loading: true }, () => {
      api
        .get(this.state.nextUrl)
        .then(this.handleResult)
        .finally(this.removeLoading);
    });
  };

  load() {
    this.setState({ loading: true }, () => {
      fetchHistory()
        .then(this.handleResult)
        .finally(this.removeLoading);
    });
  }

  withOrganizationInfo = memoize((histories, account) => {
    return histories.map(history => {
      const orgId = _.get(history, 'object.task.organization');

      const organization = _.get(
        account,
        'data.organization_is_active_set',
        []
      ).find(organization => organization.id === orgId);

      return {
        ...history,
        organization
      };
    });
  });

  group = memoize(histories => {
    let result;

    result = _.groupBy(histories, history =>
      moment(history.object.created_at)
        .startOf('month')
        .format('YYYY-MM-DD')
    );

    result = _.map(result, (histories, date) => [histories, date]);

    result = result.sort((a, b) => moment(a[0]) - moment(b[0]));

    result = _.map(result, ([histories]) => histories);

    return result;
  });

  render() {
    const { histories, nextUrl, loading } = this.state;
    const { account } = this.props;

    const avatar = _.get(account, 'data.profile.avatar_128x0');

    return (
      <HistoryScreen
        historiesGroups={this.group(
          this.withOrganizationInfo(histories, account)
        )}
        hasNext={!!nextUrl}
        onNext={this.next}
        loading={loading}
        avatar={avatar}
      />
    );
  }
}

const mapStateToProps = ({ account }) => ({ account });

export default connect(mapStateToProps, {
  dispatchUpdateProfile,
  showMessage,
  unregisterAccount
})(withRouter(HistoryScreenEnhanced));
